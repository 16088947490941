module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"colors":{"white":"#ffffff","offWhite":"#f0f0f2","black":"#000000","light":"#cfdbe6","dark":"#596e80","darkAccent":"#657d91"},"layout":{"body":{"minWidth":480,"margin":0},"content":{"maxWidth":960,"margin":[0,"auto"]},"responsive":{"twoColumns":"50rem","twoColumnsQuery":"@media (min-width: 50rem)"},"spacing":{"quarter":"0.25rem","half":"0.5rem","standard":"1rem","large":"3rem"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans","Montserrat","Dancing Script"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
